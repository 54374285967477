import { createSlice } from "@reduxjs/toolkit";

export const getUsersToChatSlice = createSlice({
  name: "getUsersToChatSlice", //it doen't matter which name you are defining here
  initialState: {
    data: null,
    loading: false,
    error: null,
  },
  reducers: {
    getUsersToChat: (state) => {
      state.loading = true;
    },
    getUsersToChatSuccess: (state, action) => {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    getUsersToChatFailure: (state, action) => {
      state.loading = false;
      state.data = null;
      state.error = action.payload;
    },
  },
});

export const {
  getUsersToChat,
  getUsersToChatSuccess,
  getUsersToChatFailure,
} = getUsersToChatSlice.actions;

export default getUsersToChatSlice.reducer;
