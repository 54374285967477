import React from "react";

const Loading = () => {
  return (
    <>
      <div className="loading-spinner-btn" />
    </>
  );
};

export default Loading;
