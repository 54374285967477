// export const urlLink1 = process.env.REACT_APP_BASE_URL_PRODUCTION;
export const urlLink1 = process.env.REACT_APP_BASE_URL;

// export const urlLink1 = "http://192.168.18.248:3000";

// const
export const LOGIN = "LOGIN";

// url
export const loginEndpoint = "V1/investorAuth/signin";
// export const popupForm = "popup-form";
// export const createCRMSupportForm = "crm-support-form/createCRMSupportForm";
