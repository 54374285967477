import { createSlice } from "@reduxjs/toolkit";

export const sendChatMessageSlice = createSlice({
  name: "sendChatMessageSlice", //it doen't matter which name you are defining here
  initialState: {
    data: null,
    loading: false,
    error: null,
  },
  reducers: {
    sendChatMessage: (state) => {
      state.loading = true;
    },
    sendChatMessageSuccess: (state, action) => {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    sendChatMessageFailure: (state, action) => {
      state.loading = false;
      state.data = null;
      state.error = action.payload;
    },
  },
});

export const {
  sendChatMessage,
  sendChatMessageSuccess,
  sendChatMessageFailure,
} = sendChatMessageSlice.actions;

export default sendChatMessageSlice.reducer;
